<template>
    <div id="evaluate" v-if="!submitEnd" :class="`evaluate-${browserEnv} ${showIcon ? 'evaluate-show-icon' : ''}`">
        <img
            @click="onShowPopup"
            class="evaluate-icon"
            src="https://res.peki.vip/20210628/14caaa1d772c4769a36a42be165d7804.png"
        />
        <van-popup closeable :class="`evaluate-popup-${browserEnv}`" v-model="isShowPopup">
            <div class="evaluate-title">您对本节课满意吗？</div>
            <div class="evaluate-course">课程：{{ titleName }}</div>
            <div class="evaluate-item">
                <div class="label"><span>*</span><span>课程内容</span></div>
                <div class="content">
                    <van-rate v-model="contentScore" color="#f8cb4e" void-icon="star" />
                </div>
                <div class="score-num">{{ contentScore }}</div>
            </div>
            <div class="evaluate-item">
                <div class="label"><span>*</span><span>讲师教学</span></div>
                <div class="content">
                    <van-rate v-model="teacherScore" color="#f8cb4e" void-icon="star" />
                </div>
                <div class="score-num">{{ teacherScore }}</div>
            </div>
            <div class="extra">
                <textarea
                    @input="onExtraChange"
                    @change="onExtraChange"
                    v-model="extra"
                    placeholder="您的声音很重要，让我们一起做的更好～"
                ></textarea>
                <div class="text-num">{{ extra.length }}/140</div>
            </div>
            <van-button :disabled="!isSubmit" @click="onSubmit" class="submit-btn">提交评价</van-button>
        </van-popup>
    </div>
</template>
<script>
import { userEvaluation } from '@/api/studyApi.js'

export default {
    props: ['courseInfo', 'packageInfo', 'isShowEvaluate'],
    data() {
        return {
            isShowPopup: false,
            browserEnv: '',

            contentScore: 0,
            teacherScore: 0,
            extra: '',
            showIcon: false,

            submitEnd: false,
        }
    },
    computed: {
        isSubmit: function () {
            return this.contentScore && this.teacherScore
        },
        titleName: function () {
            let title = ''
            if (this.courseInfo) {
                title = this.courseInfo.title
            } else if (this.packageInfo) {
                title = this.packageInfo.title
            }
            return title
        },
    },
    watch: {
        isShowEvaluate: function () {
            if (!this.isShowEvaluate) {
                this.isShowPopup = false
                this.contentScore = 0
                this.teacherScore = 0
                this.extra = ''
                this.showIcon = false
                this.submitEnd = false
            } else {
                setTimeout(() => {
                    this.showIcon = true
                }, 3000)
            }
        },
    },
    created() {
        const ua = navigator.userAgent.toLowerCase()
        const browserEnv = /iPad|Android|webOS|iPhone|iPod|BlackBerry/i.test(ua) ? 'mobile' : 'pc'
        this.browserEnv = browserEnv
    },
    methods: {
        onShowPopup() {
            this.isShowPopup = true
            this.showIcon = true
        },
        onExtraChange() {
            this.extra = this.extra.substr(0, 140)
        },
        onSubmit() {
            const { contentScore, teacherScore, extra } = this
            const params = { contentScore: contentScore, teacherScore: teacherScore, extra }
            if (this.courseInfo) {
                params.courseId = this.courseInfo.id
            } else if (this.packageInfo) {
                params.packageId = this.packageInfo.id
            }
            if (!contentScore || !teacherScore) {
                return this.$toast('请完成打分，才可以提交～～')
            }
            userEvaluation(params).then(() => {
                this.$toast('感谢您的评价')
                this.submitEnd = true
            })
        },
    },
}
</script>
<style lang="scss" scoped>
#evaluate {
    position: fixed;
    z-index: 99;
}
.evaluate-pc {
    bottom: 100px;
    left: 0;
    transition: all 0.2s;
    .evaluate-icon {
        width: 220px;
        background: none;
    }
    &.evaluate-show-icon {
        left: -172px;
    }
}
.evaluate-popup-pc {
    width: 400px;
    height: 480px;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;
    .evaluate-title {
        font-size: 20px;
        line-height: 2em;
        font-weight: 700;
    }
    .evaluate-course {
        line-height: 1.6em;
        color: #333;
        font-size: 15px;
        margin-bottom: 20px;
    }
    .evaluate-item {
        line-height: 40px;
        display: flex;
        align-items: center;
        .label {
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            span:first-child {
                padding: 0 6px;
                color: #f00;
            }
        }
        .content {
            display: flex;
            align-items: center;
            flex-grow: 1;
            margin-left: 12px;
            ::v-deep .van-icon {
                font-size: 22px;
            }
        }
        .score-num {
            flex-shrink: 1;
            width: 86px;
            font-size: 22px;
            font-weight: 700;
            color: #f8cb4e;
        }
    }
    .extra {
        margin: 20px 0;
        position: relative;
        textarea {
            padding: 12px;
            box-sizing: border-box;
            resize: none;
            outline: none;
            overflow: auto;
            height: 130px;
            width: 100%;
            display: block;
            border: none;
            font-size: 16px;
            line-height: 20px;
            background: rgba(155, 232, 135, 0.3);
        }
        .text-num {
            position: absolute;
            bottom: 4px;
            right: 4px;
            color: #999;
            font-size: 13px;
        }
    }
    .submit-btn {
        margin: 30px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6b700;
        width: 140px;
        border: none;
        height: 42px;
        font-weight: bold;
        border-radius: 4px;
        &:disabled {
            background: rgba($color: #f6b700, $alpha: 0.3);
        }
    }
}

.evaluate-mobile {
    bottom: 12px;
    left: 0;
    transition: all 0.2s;
    .evaluate-icon {
        width: 220px;
        background: none;
    }

    &.evaluate-show-icon {
        left: -173px;
    }
}
.evaluate-popup-mobile {
    width: 280px;
    height: 380px;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    .evaluate-title {
        font-size: 18px;
        line-height: 2em;
        font-weight: 700;
    }
    .evaluate-course {
        line-height: 1.6em;
        color: #333;
        font-size: 13px;
        margin-bottom: 20px;
    }
    .evaluate-item {
        line-height: 40px;
        display: flex;
        align-items: center;
        .label {
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;
            span:first-child {
                padding: 0 6px;
                color: #f00;
            }
        }
        .content {
            display: flex;
            align-items: center;
            flex-grow: 1;
            margin-left: 12px;
        }
        .score-num {
            flex-shrink: 1;
            width: 20px;
            font-size: 18px;
            font-weight: 700;
            color: #f8cb4e;
        }
    }
    .extra {
        margin: 16px 0;
        position: relative;
        textarea {
            padding: 12px;
            box-sizing: border-box;
            resize: none;
            outline: none;
            overflow: auto;
            height: 80px;
            width: 100%;
            display: block;
            border: none;
            font-size: 14px;
            line-height: 20px;
            background: rgba(155, 232, 135, 0.3);
        }
        .text-num {
            position: absolute;
            bottom: 4px;
            right: 4px;
            color: #999;
            font-size: 12px;
        }
    }
    .submit-btn {
        margin: 18px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6b700;
        border: none;
        width: 120px;
        height: 36px;
        font-weight: bold;
        border-radius: 4px;
        &:disabled {
            background: rgba($color: #f6b700, $alpha: 0.3);
        }
    }
}
</style>
