<template>
    <van-popup class="agmAffirm" @close="onClose" v-model="show" get-container="body" :close-on-click-overlay="false">
        <div class="iframe-container">
            <iframe
                v-if="agmInfo"
                :src="`${PROJECT_PATH}/agm.html?agmId=${agmInfo.agmId}&no=${agmInfo.agreeNo}&dpr=1`"
            />
        </div>
        <div class="form" v-if="agmInfo">
            <div class="item">
                <div class="label">姓名</div>
                <input v-model="agmName" />
            </div>
            <div class="item">
                <div class="label">手机号</div>
                <input :value="agmInfo.telNo" disabled />
            </div>
            <div class="item margin-40">
                <div class="label">身份证号</div>
                <input v-model="IDCard" placeholder="请输入身份证号" />
                <div class="length">{{ IDCard.length || '' }}</div>
                <div class="warning">
                    <template v-if="isShowWarning">身份证格式错误，请检测是否正确</template>
                </div>
            </div>
        </div>
        <van-button class="submit-btn" block @click="onSubmit">确认</van-button>
    </van-popup>
</template>
<script>
import { mapGetters } from 'vuex'
import { signAgreement } from '@/api/userApi.js'

const reg18 = new RegExp(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)
const reg15 = new RegExp(/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/)
export default {
    props: ['isShow', 'agmInfo'],
    data() {
        return {
            show: false,
            isShowWarning: false,

            agmName: '',
            IDCard: '',
        }
    },
    computed: { ...mapGetters(['PROJECT_PATH']) },
    watch: {
        isShow: function (isShow) {
            this.show = isShow
            this.agmName = this.agmInfo.name
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
        onSubmit() {
            this.isShowWarning = false
            try {
                const state = reg18.test(this.IDCard) === false && reg15.test(this.IDCard) === false
                if (state) {
                    this.isShowWarning = true
                } else {
                    signAgreement({
                        orderNo: this.agmInfo.orderNo,
                        certNo: this.IDCard,
                        name: this.agmName,
                    }).then(() => {
                        this.show = false
                        this.onClose()
                    })
                }
            } catch (error) {
                this.$toast({
                    duration: 0,
                    message: '系统异常，请联系助教老师',
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.agmAffirm {
    width: 500px;
    height: 640px;
    border-radius: 4px;
    .iframe-container {
        width: 100%;
        margin: 0 auto;
        display: block;
        height: 400px;
        overflow-y: scroll;
        iframe {
            width: 100%;
            height: 99%;
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
    }
    .form {
        padding: 20px 40px 0;
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            position: relative;
        }
        .margin-40 {
            margin-bottom: 40px;
        }
        .label {
            flex-shrink: 0;
            width: 60px;
            font-size: 14px;
            color: #666;
            text-align: right;
            margin-right: 12px;
            text-align-last: justify;
        }
        input {
            width: 350px;
            height: 32px;
            font-size: 15px;
            padding: 0 30px 0 12px;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 4px;
            &:disabled {
                color: #444;
                background: #efefef;
            }
        }
        .length {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
            color: #c0c4cc;
            line-height: 14px;
            font-size: 13px;
        }
        .warning {
            position: absolute;
            left: 96px;
            top: 40px;
            font-size: 12px;
            color: #f56c6c;
            height: 1.4em;
        }
    }
    .submit-btn {
        background: $theme-color;
        width: 120px;
        margin: 0 auto;
        height: 36px;
        font-size: 15px;
        border-radius: 4px;
    }
}

@media screen and (max-width: 640px) {
    .agmAffirm {
        width: 90vw;
        height: 90vh;
        max-width: 540px;
        max-height: 800px;
        .iframe-container {
            width: 100%;
            height: calc(100% - 220px);
        }
        .form {
            padding: 20px 16px 0;
            .label {
                flex-shrink: 0;
                width: 60px;
                font-size: 13px;
                color: #666;
                text-align: right;
            }
            input {
                flex-grow: 1;
                height: 28px;
                width: 150px;
                font-size: 14px;
                padding: 0 20px 0 12px;
            }
            .warning {
                left: 72px;
            }
        }
        .submit-btn {
            width: 100px;
            height: 30px;
            margin: 0 auto 12px;
            font-size: 14px;
        }
    }
}
</style>
